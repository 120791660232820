import React, { useEffect } from 'react';
import styled from 'styled-components';
import PagesContainer from '../../components/PagesContainer';
import NavBar from '../../components/NavBar';
import Container, {
  ContainerDescription,
} from '../../components/Container/Container';
import useScript from '../../hooks/addScript';

const ThesisGeneratorPage = () => {
  const [loaded, error] = useScript({
    src: `${process.env.PUBLIC_URL}/ThesisGenerator/thesis-generator.js`,
  });

  useEffect(() => {
    if (loaded && !error) {
      window.thesisGeneratorComponents = () => {
        return {
          setResultPageFooter: (React) => {
            return React.createElement(
              'div',
              { className: 'thesis-generator-banner' },
              ''
            );
          },
        };
      };
    }

    return () => (window.thesisGeneratorComponents = null);
  });

  return (
    <PagesContainer>
      <NavBar />
      <Container>
        <h1>Thesis statement generator</h1>
        <ContainerDescription>
          Create a strong thesis statement with our online tool and clearly
          express ideas in your paper.
        </ContainerDescription>
        {loaded && error ? (
          <Error>Oops! Something went wrong! :(</Error>
        ) : (
          <ThesisGenerator
            id="thesis-generator-container"
            className="thesis-generator-app"
          />
        )}
      </Container>
    </PagesContainer>
  );
};

const Error = styled.div`
  padding: 2rem 0;
`;

const ThesisGenerator = styled.div`
  margin-top: 1rem;

  form {
    padding: 0;
    background: none;
    border: 0;
  }

  button {
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    outline: none;
    position: relative;
    display: inline;
    border: 0;

    &[type='submit'] {
      width: 100%;
      padding: 9px 35px 9px 15px;
      color: #222;
      background-color: #fbcb1f;
      border-radius: 2px;
      text-transform: lowercase;
      max-width: 420px;
      margin: 0 auto;
    }

    &[type='button'] {
      color: #53c6fd;
      text-align: left;
      margin: 0;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export default ThesisGeneratorPage;
