import React from 'react';
import { NavLink } from 'react-router-dom';
import Container, {
  ContainerDescription,
} from '../../components/Container/Container';
import styled, { keyframes } from 'styled-components';
import linkArrowIcon from '../../assets/images/icon_link-arrow.svg';
import gpaCalcIcon from '../../assets/images/icon_gpa-calculator.svg';
import wtmIcon from '../../assets/images/icon_words-to-minutes.svg';
import wtpIcon from '../../assets/images/icon_words-to-pages.svg';
import statementIcon from '../../assets/images/icon_statement-generator.svg';
import logo from '../../assets/images/logo.svg';

const HomePage = () => (
  <HomePageElm>
    <Container>
      <Logo src={logo} alt="" />
      <h1>Free online writing tools</h1>
      <ContainerDescription>
        We are happy to assist you in creating your papers
      </ContainerDescription>
    </Container>
    <HomeNav>
      <NavItem to="/gpa-calculator" icon={gpaCalcIcon}>
        GPA Calculator
      </NavItem>
      <NavItem to="/words-to-minutes" icon={wtmIcon}>
        Words to Minutes Converter
      </NavItem>
      <NavItem to="/pages-calculation" icon={wtpIcon}>
        Words to pages converter
      </NavItem>
      <NavItem to="/thesis-statement-generator" icon={statementIcon}>
        Thesis statement generator
      </NavItem>
    </HomeNav>
  </HomePageElm>
);

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
`;

const Page = styled.div`
  background-color: white;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  width: 130px;
  height: 62px;
  margin-bottom: 35px;
`;

const HomeNav = styled.div`
  margin: 10px 0 0;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const HomePageElm = styled(Page)`
  &.page-enter {
    animation: ${slideInLeft} 0.4s forwards;
  }
  &.page-exit {
    animation: ${slideOutLeft} 0.4s forwards;
  }
`;

const NavItem = styled(NavLink)`
  position: relative;
  padding: 1rem 0;
  width: 100%;
  text-decoration: none;
  font-size: 17px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: -0.408px;
  max-width: 774px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 1rem 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #c8c7cc;
  }

  &::after {
    content: '';
    width: 20px;
    height: 12px;
    background: url(${linkArrowIcon}) no-repeat;
    margin-left: auto;
  }

  &::before {
    content: '';
    width: 44px;
    height: 44px;
    background: url(${(props) => props.icon}) center no-repeat;
    margin-right: 0.75rem;
  }
`;

export default HomePage;
